import { useState, useEffect } from "react";
// import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	TextField,
} from "@mui/material";
import { toast } from "react-toastify";

const categories = [
	{
		value: "Buddhism",
		label: "Buddhism",
	},
	{
		value: "History",
		label: "History",
	},
	{
		value: "General Book",
		label: "General Book",
	},
	{
		value: "Politics",
		label: "Politics",
	},
	{
		value: "Politics",
		label: "Politics",
	},
	{
		value: "Dhamma",
		label: "Dhamma",
	},
	{
		value: "Literatures",
		label: "Literatures",
	},
];

const languages = [
	{
		value: "Shan",
		label: "Shan",
	},
	{
		value: "Burmese",
		label: "Burmese",
	},
	{
		value: "English",
		label: "English",
	},
];
export const BookForm = ({
	id,
	imageUrl,
	book,
	value,
	updateBook,
	createBook,
}) => {
	const navigate = useNavigate();
	const [values, setValues] = useState({
		title: "",
		author: "",
		des: "",
		imageUrl: "",
		url: "",
		fileSize: "",
		pages: "",
		category: "",
		language: "",
	});

	useEffect(() => {
		setValues({
			title: book?.title,
			author: book?.author,
			des: book?.des,
			imageUrl: book?.imageUrl,
			url: book?.url,
			fileSize: book?.fileSize,
			pages: book?.pages,
			category: book?.category,
			language: book?.language,
		});
	}, [book]);

	const handleChange = (event) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};
	const handleSave = async (e) => {
		if (id) {
			const bodyReq = {
				id,
				...values,
				imageUrl,
				url: value?.url,
				fileSize: value?.fileSize,
				pages: value?.pages,
			};
			await updateBook(bodyReq);

			navigate("/", { replace: true });
		} else {
			const createBodyReq = {
				...values,
				imageUrl,
				url: value?.url,
				fileSize: value?.fileSize,
				pages: value?.pages,
			};

			await createBook(createBodyReq);

			// toast.success("Created Book Successfully!");
			navigate("/", { replace: true });
		}

		toast.success(`Book ${id ? "Updated" : "Created"} Successfully!!`);
	};

	return (
		<form autoComplete="off" noValidate>
			<Card>
				<CardHeader
					subheader="The information can be edited"
					title="Book Info"
				/>
				<Divider />
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Title"
								name="title"
								onChange={handleChange}
								// required
								value={values.title}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Author"
								name="author"
								onChange={handleChange}
								// required
								value={values.author}
								variant="outlined"
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Select Language"
								name="language"
								onChange={handleChange}
								required
								select
								SelectProps={{ native: true }}
								value={values.language}
								variant="outlined"
							>
								{languages.map((option) => (
									<option
										key={option.value}
										value={option.value}
									>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>

						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Select Category"
								name="category"
								onChange={handleChange}
								required
								select
								SelectProps={{ native: true }}
								value={values.category}
								variant="outlined"
							>
								{categories.map((option) => (
									<option
										key={option.value}
										value={option.value}
									>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Overview"
								name="des"
								multiline
								type="string"
								onChange={handleChange}
								// required
								value={values.des}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</CardContent>
				<Divider />
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						p: 2,
					}}
				>
					<Button
						onClick={handleSave}
						color="primary"
						variant="contained"
					>
						Save details
					</Button>
				</Box>
			</Card>
		</form>
	);
};
