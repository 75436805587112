import { getAuth, GoogleAuthProvider } from "firebase/auth";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCCQnvhA-Uyv9MuOrNnzZMs-3oHkwR5YL8",
	authDomain: "haohaa-book.firebaseapp.com",
	projectId: "haohaa-book",
	storageBucket: "haohaa-book.appspot.com",
	messagingSenderId: "525414156162",
	appId: "1:525414156162:web:f0216a471685c36ddb319b",
	measurementId: "G-7HYG5CY5KW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const provider = new GoogleAuthProvider();
export const auth = getAuth(app);
export const db = getFirestore(app);
