import { GET_AUTHOR, UPDATE_AUTHOR, GET_AUTHORS } from "../actions/actionTypes";

const initialState = {
	author: null,
	authors: [],
	loading: true,
	error: {},
};

export const author = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_AUTHOR:
		case UPDATE_AUTHOR:
			return {
				...state,
				author: payload,
				loading: false,
			};
		case GET_AUTHORS:
			return {
				...state,
				authors: payload,
				loading: false,
			};

		default:
			return state;
	}
};
