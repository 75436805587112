import { combineReducers } from "redux";
import { auth } from "./auth";
import { user } from "./user";
import { book } from "./book";
import { author } from "./author";
import { category } from "./category";

export default combineReducers({
	auth,
	user,
	book,
	author,
	category,
});
