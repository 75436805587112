import { PDFDocument } from "pdf-lib";
// var input = document.getElementById("files");
// var reader = new FileReader();
// reader.readAsBinaryString(input.files[0]);
// reader.onloadend = function(){
//     var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
//     console.log('Number of Pages:',count );
// }

function niceBytes(x) {
	const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	let l = 0,
		n = parseInt(x, 10) || 0;
	while (n >= 1024 && ++l) {
		n = n / 1024;
	}
	return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

export function getFileSize(url) {
	var fileSize = "";
	var http = new XMLHttpRequest();
	http.open("HEAD", url, false); // false = Synchronous

	http.send(null); // it will stop here until this http request is complete

	// when we are here, we already have a response, b/c we used Synchronous XHR

	if (http.status === 200) {
		fileSize = http.getResponseHeader("content-length");
		console.log("fileSize = " + fileSize);
	}

	return niceBytes(fileSize);
}

export const getPageCount = async (url) => {
	const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
	const pdfDoc = await PDFDocument.load(existingPdfBytes);
	const pages = pdfDoc.getPages();
	console.log("here i spage:", pages);
	return pages;
};
