import {
	doc,
	getDocs,
	getDoc,
	setDoc,
	updateDoc,
	collection,
	deleteDoc,
	serverTimestamp,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { CLEAR_BOOK, GET_BOOK, GET_BOOKS } from "./actionTypes";
import { db } from "../../firebase-config";

export const getBook = (id) => async (dispatch) => {
	try {
		dispatch({
			type: CLEAR_BOOK,
		});
		const bookSnapshot = await getDoc(doc(db, "books", id));
		if (bookSnapshot.exists()) {
			// clear old book
			dispatch({
				type: GET_BOOK,
				payload: bookSnapshot.data(),
			});
		}
	} catch (error) {
		console.log("her eis errro:", error);
	}
};

// get all books
export const getBooks = () => async (dispatch) => {
	try {
		const booksSnapshot = await getDocs(collection(db, "books"));
		const booksList = booksSnapshot.docs.map((doc) => doc.data());
		dispatch({
			type: GET_BOOKS,
			payload: booksList,
		});
	} catch (error) {
		console.log("her eis errro:", error);
	}
};

// create Book Profile
export const createBook =
	({
		title,
		author,
		des,
		imageUrl,
		url,
		pages,
		fileSize,
		category,
		language = "Shan",
	}) =>
	async (dispatch) => {
		try {
			const uid = uuidv4();
			const noteSnapshot = await getDoc(doc(db, "books", uid));
			if (!noteSnapshot.exists()) {
				// create new user in database
				await setDoc(doc(db, "books", uid), {
					bookId: uid,
					title,
					author,
					des,
					imageUrl,
					url,
					pages,
					fileSize,
					category,
					language,
					createdAt: serverTimestamp(),
				});
			}

			dispatch(getBook(uid));
		} catch (err) {
			console.log("udpate book; error", err);
			// dispatch({
			// 	type: UPDATE_PROFILE,
			// });
		}
	};

// update Book Profile
export const updateBook =
	({
		id,
		title,
		author,
		des,
		imageUrl,
		url,
		pages,
		fileSize,
		category,
		language = "Shan",
	}) =>
	async (dispatch) => {
		try {
			const bookRef = doc(db, "books", id);
			const bodyReq = {
				title,
				author,
				des,
				imageUrl,
				url,
				pages,
				fileSize,
				category,
				language,
			};
			await updateDoc(bookRef, bodyReq);

			dispatch(getBook(id));
		} catch (err) {
			console.log("udate book; error", err);
			// dispatch({
			// 	type: UPDATE_PROFILE,
			// });
		}
	};

export const deleteBook = (id) => async (dispatch) => {
	try {
		const bookRef = doc(db, "books", id);
		await deleteDoc(bookRef);
		dispatch(getBooks());
	} catch (error) {
		console.log("here is errro:", error);
	}
};
