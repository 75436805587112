import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
	Link,
	Stack,
	Checkbox,
	TextField,
	IconButton,
	InputAdornment,
	FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
// redux
import { connect } from "react-redux";
import { login } from "../../../redux/actions/auth";

// ----------------------------------------------------------------------

const LoginForm = ({ login, isAuthenticated }) => {
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email("Email must be a valid email address")
			.required("Email is required"),
		password: Yup.string().required("Password is required"),
	});

	useEffect(() => {
		if (isAuthenticated) {
			return navigate("/", { replace: true });
		}
	}, [isAuthenticated]);

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
			remember: true,
		},
		validationSchema: LoginSchema,
		onSubmit: async () => {
			await login(values.email, values.password);
			if (isAuthenticated) {
				toast.success("Login Successfuly!");
				return navigate("/", { replace: true });
			}
		},
	});

	const {
		errors,
		touched,
		values,
		isSubmitting,
		handleSubmit,
		getFieldProps,
	} = formik;

	const handleShowPassword = () => {
		setShowPassword((show) => !show);
	};

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<TextField
						fullWidth
						autoComplete="username"
						type="email"
						label="Email address"
						{...getFieldProps("email")}
						error={Boolean(touched.email && errors.email)}
						helperText={touched.email && errors.email}
					/>

					<TextField
						fullWidth
						autoComplete="current-password"
						type={showPassword ? "text" : "password"}
						label="Password"
						{...getFieldProps("password")}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={handleShowPassword}
										edge="end"
									>
										<Icon
											icon={
												showPassword
													? eyeFill
													: eyeOffFill
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
						error={Boolean(touched.password && errors.password)}
						helperText={touched.password && errors.password}
					/>
				</Stack>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					sx={{ my: 2 }}
				>
					<FormControlLabel
						control={
							<Checkbox
								{...getFieldProps("remember")}
								checked={values.remember}
							/>
						}
						label="Remember me"
					/>

					<Link component={RouterLink} variant="subtitle2" to="#">
						Forgot password?
					</Link>
				</Stack>

				<LoadingButton
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					loading={isSubmitting}
				>
					Login
				</LoadingButton>
			</Form>
		</FormikProvider>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { login })(LoginForm);
