import {
	GET_CATEGORY,
	UPDATE_AUTHOR,
	GET_CATEGORIES,
} from "../actions/actionTypes";

const initialState = {
	category: null,
	categories: [],
	loading: true,
	error: {},
};

export const category = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_CATEGORY:
		case UPDATE_AUTHOR:
			return {
				...state,
				category: payload,
				loading: false,
			};
		case GET_CATEGORIES:
			return {
				...state,
				categories: payload,
				loading: false,
			};

		default:
			return state;
	}
};
