import { Icon } from "@iconify/react";
import peopleFill from "@iconify/icons-eva/people-fill";
import bookFill from "@iconify/icons-eva/book-fill";
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
	{
		title: "user",
		path: "/user",
		icon: getIcon(peopleFill),
	},
	{
		title: "book",
		path: "/book",
		icon: getIcon(bookFill),
	},
];

export default sidebarConfig;
