import {
	GET_BOOK,
	UPDATE_BOOK,
	GET_BOOKS,
	CLEAR_BOOK,
} from "../actions/actionTypes";

const initialState = {
	book: null,
	books: [],
	loading: true,
	error: {},
};

export const book = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case GET_BOOK:
		case UPDATE_BOOK:
			return {
				...state,
				book: payload,
				loading: false,
			};
		case GET_BOOKS:
			return {
				...state,
				books: payload,
				loading: false,
			};
		case CLEAR_BOOK:
			return {
				...state,
				book: null,
			};
		default:
			return state;
	}
};
