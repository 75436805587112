import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Divider,
	Typography,
} from "@mui/material";
import Compressor from "compressorjs";
import { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import slugify from "slugify";
import {
	ref,
	uploadBytesResumable,
	getDownloadURL,
	getStorage,
} from "firebase/storage";

const BookInfo = ({ handleImage, book, id }) => {
	const inputRef = useRef(null);
	const [avatarUrl, setAvatarUrl] = useState(" ");
	const [isUpload, setUpload] = useState(false);

	useEffect(() => {
		setAvatarUrl(book?.imageUrl);
		handleImage(book?.imageUrl);
	}, [book?.imageUrl]);

	const readFile = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				resolve(reader.result);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	const handleUploadFile = async (e) => {
		const file = inputRef.current.files[0];

		new Compressor(file, {
			quality: 0.6,

			// The compression process is asynchronous,
			// which means you have to access the `result` in the `success` hook function.
			success: async (result) => {
				const fileData = await readFile(result);
				const fileName = slugify(result?.name);
				const myURL = encodeURI(`img/${fileName}`);

				try {
					const response = await fetch(
						`https://upload.haohaa.com/.netlify/functions/image?fileName=${myURL}`,
						{
							method: "POST",
							body: fileData,
						}
					);
					const data = await response.json();
					setAvatarUrl(data?.url);
					handleImage(data?.url);
					setUpload(true);
					toast.success(`Upload Image Successfully!`);
				} catch (error) {
					console.log("hrere is upload error:", error);
				}
			},
			error(err) {
				console.log(err.message);
			},
		});
	};

	// const handleUploadFile = (event) => {
	// 	const reader = new FileReader();
	// 	reader.onload = (e) => {
	// 		//   poImg.current = e.target.result;
	// 	};
	// 	const file = event.target.files[0];
	// 	reader.readAsDataURL(file);
	// 	setMessage("Uploading...");
	// 	const storage = getStorage();
	// 	const storageRef = ref(storage, `books/${file.name}`);
	// 	const uploadTask = uploadBytesResumable(storageRef, file, "data_url");
	// 	uploadTask.on(
	// 		"state_changed",
	// 		(snapshot) => {
	// 			const progress =
	// 				(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
	// 			setMessage(`Upload is  ${progress}% done`);
	// 		},
	// 		(error) => {
	// 			// Handle unsuccessful uploads
	// 			throw error;
	// 		},
	// 		() => {
	// 			// Handle successful uploads on complete
	// 			// For instance, get the download URL: https://firebasestorage.googleapis.com/...
	// 			getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
	// 				setAvatarUrl(downloadURL);
	// 				handleImage(downloadURL);
	// 				setUpload(true);
	// 				toast.success(`Upload Image Successfully!`);
	// 			});
	// 		}
	// 	);
	// };

	return (
		<Card>
			<CardContent>
				<Box
					sx={{
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<CardMedia
						component="img"
						width="100"
						height="150"
						image={avatarUrl ? avatarUrl : ""}
						alt={book?.title}
					/>
					<Typography color="textPrimary" gutterBottom variant="h5">
						{book?.title}
					</Typography>
				</Box>
			</CardContent>
			<Divider />
			<CardActions>
				<Button
					onClick={() => {
						inputRef.current.value = "";
						inputRef.current.click();
					}}
					color="primary"
					fullWidth
					variant="text"
				>
					Upload picture
				</Button>
			</CardActions>
			<input
				onChange={handleUploadFile}
				type="file"
				id="my_photo"
				accept="image/*"
				ref={inputRef}
				style={{
					display: "none",
				}}
			/>
		</Card>
	);
};

export default BookInfo;
