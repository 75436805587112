// material
import { useState } from "react";
import { Container, Typography, Box, Grid } from "@mui/material";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getBook, createBook } from "../redux/actions/book";
// components
import Page from "../components/Page";
import { BookForm } from "../components/book/BookForm";
import BookInfo from "../components/book/BookInfo";
import UploadFile from "../components/book/UploadFile";
// ----------------------------------------------------------------------

const CreateBook = ({ book, createBook }) => {
	// Get ID from URL

	const [imageUrl, setImageUrl] = useState("");

	const [value, setValues] = useState({
		url: "",
		filesize: "",
		pages: 0,
	});
	return (
		<Page title="Dashboard: Create Book | HaoHaa">
			<Container>
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						py: 8,
					}}
				>
					<Container maxWidth="lg">
						<Typography sx={{ mb: 3 }} variant="h4">
							Book
						</Typography>
						<Grid container spacing={3}>
							<Grid item lg={4} md={6} xs={12}>
								<BookInfo
									value={value}
									handleImage={(url) => setImageUrl(url)}
								/>
							</Grid>
							<Grid item lg={8} md={6} xs={12}>
								<BookForm
									value={value}
									createBook={createBook}
									imageUrl={imageUrl}
								/>
							</Grid>

							<Grid item lg={8} md={6} xs={12}>
								<UploadFile
									book={book}
									handleFile={(value) => {
										setValues(value);
									}}
								/>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Container>
		</Page>
	);
};

const mapStateToProps = (state) => ({
	book: state.book.book,
});
export default connect(mapStateToProps, { getBook, createBook })(CreateBook);
