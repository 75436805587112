import React, { useRef, useState, useEffect } from "react";
import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { getFileSize, getPageCount } from "./pdf";

const UploadFile = ({ handleFile, book = null }) => {
	const inputRef = useRef(null);
	const [value, setValues] = useState({
		url: "",
		fileSize: "",
		pages: "",
	});

	useEffect(() => {
		handleFile(book);
		setValues({
			url: book?.url,
			fileSize: book?.fileSize,
			pages: book?.pages,
		});
	}, [book]);

	useEffect(() => {
		handleFile(value);
	}, [value]);

	const readFile = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				resolve(reader.result);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});

	const handleUploadFile = async (e) => {
		const file = inputRef.current.files[0];
		const fileData = await readFile(file);
		try {
			const myURL = encodeURI(`ShanYomaBooks/${file?.name}`);
			const response = await fetch(
				`https://upload.haohaa.com/.netlify/functions/upload?fileName=${myURL}`,
				{
					method: "POST",
					body: fileData,
				}
			);
			const data = await response.json();
			const pageCount = await getPageCount(data?.url);
			const fileSize = await getFileSize(data?.url);

			setValues({
				url: data?.url,
				fileSize,
				pages: pageCount?.length,
			});
		} catch (error) {
			console.log("hrere is upload error:", error);
		}
	};

	const handleChange = (e) => {
		setValues({
			...value,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<>
			<Card>
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="File Url"
								name="url"
								onPaste={async (e) => {
									try {
										const _url =
											e.clipboardData.getData("Text");

										const file = await getFileSize(_url);
										const pages = await getPageCount(_url);
										console.log(
											"here is file:",
											file,
											pages?.length
										);
										if (file && pages) {
											setValues({
												...value,
												url: _url,
												fileSize: file,
												pages: pages?.length,
											});
										}
									} catch (error) {
										console.log("here is catch:", error);
									}
								}}
								onChange={handleChange}
								// required
								value={value?.url}
							/>
							<Button
								onClick={() => {
									inputRef.current.value = "";
									inputRef.current.click();
								}}
								color="primary"
								fullWidth
								variant="text"
							>
								Upload File
							</Button>
						</Grid>

						<input
							onChange={handleUploadFile}
							type="file"
							id="my_photo"
							accept="application/*"
							ref={inputRef}
							style={{
								display: "none",
							}}
						/>

						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="File Size"
								name="fileSize"
								onChange={handleChange}
								type="string"
								value={value.fileSize}
								variant="outlined"
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label="Pages"
								name="pages"
								onChange={handleChange}
								type="string"
								value={value?.pages}
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};

export default UploadFile;
