// material
import { Grid, Container, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getBook, updateBook } from "../redux/actions/book";
// components
import Page from "../components/Page";
import BookInfo from "../components/book/BookInfo";
import { BookForm } from "../components/book/BookForm";
import UploadFile from "../components/book/UploadFile";

// ----------------------------------------------------------------------

const BookDetail = ({ book, getBook, updateBook }) => {
	// Get ID from URL
	const params = useParams();
	const [imageUrl, setImageUrl] = useState("");
	const [value, setValues] = useState({
		url: "",
		filesize: "",
		pages: 0,
	});

	useEffect(() => {
		if (params.id) {
			getBook(params.id);
		}
	}, [params.id]);

	return (
		<Page title="Dashboard: Book Detail | HaoHaa">
			<Container>
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						py: 8,
					}}
				>
					<Container maxWidth="lg">
						<Typography sx={{ mb: 3 }} variant="h4">
							Book
						</Typography>
						<Grid container spacing={3}>
							<Grid item lg={4} md={6} xs={12}>
								<BookInfo
									handleImage={(url) => setImageUrl(url)}
									book={book}
									id={params.id}
								/>
							</Grid>
							<Grid item lg={8} md={6} xs={12}>
								<BookForm
									id={params.id}
									imageUrl={imageUrl}
									book={book}
									value={value}
									updateBook={updateBook}
								/>
							</Grid>

							<Grid item lg={8} md={6} xs={12}>
								<UploadFile
									id={params?.id}
									book={book}
									handleFile={(value) => {
										setValues(value);
									}}
								/>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Container>
		</Page>
	);
};

const mapStateToProps = (state) => ({
	book: state.book.book,
});
export default connect(mapStateToProps, { getBook, updateBook })(BookDetail);
