export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_AUTH_PROFILE = "GET_AUTH_PROFILE";
export const GET_USERS = "GET_USERS";
export const GET_REPOS = "GET_REPOS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const CLEAR_BOOK = "CLEAR_BOOK";
export const PROFILE_ERROR = "PROFIL_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";

export const GET_AUTHORS = "GET_AUTHORS";
export const GET_AUTHOR = "GET_AUTHOR";
export const UPDATE_AUTHOR = "UPDATE_AUTHOR";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";

export const GET_BOOKS = "GET_BOOKS";
export const GET_BOOK = "GET_BOOK";
export const DELETE_BOOK = "DELETE_BOOK";
export const ADD_BOOK = "ADD_BOOK";
export const UPDATE_BOOK = "UPDATE_BOOK";

export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
// @react-intl
export const SET_LOCALE = "SET_LOCALE";
export const GET_LOCALE = "GET_LOCALE";
// @get and set topic id
export const GET_TOPIC_ID = "GET_TOPIC_ID";
export const SET_TOPIC_ID = "SET_TOPIC_ID";

// get slug
export const GET_SLUG = "GET_SLUG";
// set Meta
export const SET_META = "SET_META";

// toggles
export const TOGGLE_MODEL = "TOGGLE_MODEL";
