import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { toast } from "react-toastify";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
// material
import {
	Menu,
	MenuItem,
	IconButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";

// ----------------------------------------------------------------------

export default function BookMoreMenu({ id, deleteBook }) {
	const ref = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	const handleDeleteBook = async () => {
		await deleteBook(id);
		toast.success("Delete Book Successfully!");
	};

	return (
		<>
			<IconButton ref={ref} onClick={() => setIsOpen(true)}>
				<Icon icon={moreVerticalFill} width={20} height={20} />
			</IconButton>

			<Menu
				open={isOpen}
				anchorEl={ref.current}
				onClose={() => setIsOpen(false)}
				PaperProps={{
					sx: { width: 200, maxWidth: "100%" },
				}}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<MenuItem
					sx={{ color: "text.secondary" }}
					onClick={handleDeleteBook}
				>
					<ListItemIcon>
						<Icon icon={trash2Outline} width={24} height={24} />
					</ListItemIcon>
					<ListItemText
						primary="Delete"
						primaryTypographyProps={{ variant: "body2" }}
					/>
				</MenuItem>

				<MenuItem
					component={RouterLink}
					to={`/book/${id}`}
					sx={{ color: "text.secondary" }}
				>
					<ListItemIcon>
						<Icon icon={editFill} width={24} height={24} />
					</ListItemIcon>
					<ListItemText
						primary="Edit"
						primaryTypographyProps={{ variant: "body2" }}
					/>
				</MenuItem>
			</Menu>
		</>
	);
}
