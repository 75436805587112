import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import {
	Card,
	Table,
	Stack,
	Avatar,
	Button,
	Checkbox,
	TableRow,
	TableBody,
	TableCell,
	Container,
	Typography,
	TableContainer,
	TablePagination,
} from "@mui/material";
import { connect } from "react-redux";
import { getBooks, deleteBook } from "../redux/actions/book";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
	BookListHead,
	BookListToolbar,
	BookMoreMenu,
} from "../components/_dashboard/book";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
	{ id: "title", label: "Title", alignRight: false },
	{ id: "author", label: "Author", alignRight: false },
	{ id: "des", label: "Overview", alignRight: false },
	// { id: 'isVerified', label: 'Verified', alignRight: false },
	// { id: 'status', label: 'Status', alignRight: false },
	// { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(
			array,
			(_book) =>
				_book.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
		);
	}
	return stabilizedThis?.map((el) => el[0]);
}

const Book = ({ loading, books, getBooks, deleteBook }) => {
	const [page, setPage] = useState(0);
	const [order, setOrder] = useState("asc");
	const [selected, setSelected] = useState([]);
	const [orderBy, setOrderBy] = useState("name");
	const [filterName, setFilterName] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(5);
	useEffect(async () => {
		await getBooks();
	}, []);
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = books.map((n) => n.title);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - books.length) : 0;

	const filteredBooks = applySortFilter(
		books,
		getComparator(order, orderBy),
		filterName
	);

	const isBookNotFound = filteredBooks?.length === 0;

	if (loading || books?.length < 0) {
		return <div>loading ...</div>;
	}

	return (
		<Page title="Book | HaoHaa">
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						Book
					</Typography>
					<Button
						variant="contained"
						href="/book/create"
						startIcon={<Icon icon={plusFill} />}
					>
						Add New Book
					</Button>
				</Stack>

				<Card>
					<BookListToolbar
						numSelected={selected?.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/>

					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table>
								<BookListHead
									order={order}
									orderBy={orderBy}
									headLabel={TABLE_HEAD}
									rowCount={books?.length}
									numSelected={selected?.length}
									onRequestSort={handleRequestSort}
									onSelectAllClick={handleSelectAllClick}
								/>
								<TableBody>
									{filteredBooks
										?.slice(
											page * rowsPerPage,
											page * rowsPerPage + rowsPerPage
										)
										?.map((row) => {
											const {
												bookId,
												title,
												author,
												des,
												imageUrl,
											} = row;
											const isItemSelected =
												selected?.indexOf(title) !== -1;

											return (
												<TableRow
													hover
													key={bookId}
													tabIndex={-1}
													role="checkbox"
													selected={isItemSelected}
													aria-checked={
														isItemSelected
													}
												>
													<TableCell padding="checkbox">
														<Checkbox
															checked={
																isItemSelected
															}
															onChange={(event) =>
																handleClick(
																	event,
																	title
																)
															}
														/>
													</TableCell>
													<TableCell
														component="th"
														scope="row"
														padding="none"
													>
														<Stack
															direction="row"
															alignItems="center"
															spacing={2}
														>
															<Avatar
																alt={title}
																src={imageUrl}
																variant="rounded"
															/>
															<Typography
																variant="subtitle2"
																noWrap
															>
																{title?.substr(
																	0,
																	50
																)}
																...
															</Typography>
														</Stack>
													</TableCell>

													{/* <TableCell align="left">
														{descendingComparator}
													</TableCell> */}
													<TableCell align="left">
														{author}
													</TableCell>
													<TableCell align="left">
														{des}
													</TableCell>
													{/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}
													{/* <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(status === 'banned' && 'error') || 'success'}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell> */}

													<TableCell align="right">
														<BookMoreMenu
															id={bookId}
															deleteBook={
																deleteBook
															}
														/>
													</TableCell>
												</TableRow>
											);
										})}
									{emptyRows > 0 && (
										<TableRow
											style={{ height: 53 * emptyRows }}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
								{isBookNotFound && (
									<TableBody>
										<TableRow>
											<TableCell
												align="center"
												colSpan={6}
												sx={{ py: 3 }}
											>
												<SearchNotFound
													searchQuery={filterName}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								)}
							</Table>
						</TableContainer>
					</Scrollbar>

					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={books?.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			</Container>
		</Page>
	);
};

const mapStateToProps = (state) => ({
	books: state.book.books,
	loading: state.book.loading,
});

export default connect(mapStateToProps, { getBooks, deleteBook })(Book);
